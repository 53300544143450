import React, { Component } from 'react';

class Players extends Component {
    constructor(props) {
        super(props);
        this.state = {
          error: null,
          isLoaded: false,
          player: []
        };
      }
    
      componentDidMount() {
        fetch("https://gremlis.jmsgvn.workers.dev/?steamid=" + this.props.steamid)
          .then(res => res.json())
          .then(
            (result) => {
              this.setState({
                isLoaded: true,
                player: result.playerstats
              });
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
              this.setState({
                isLoaded: true,
                error
              });
            }
          )
      }
    
      render() {
        const { error, isLoaded, player } = this.state;
        if (error) {
          return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
          return <div>Loading...</div>;
        } else {

          let kills = -1;
          let deaths = -1;
          let playtime = -1;

          for (var i = 0; i< player.stats.length; i++) {
            if(player.stats[i].name === 'total_kills') {
              kills = player.stats[i].value;
              continue;
            }

            if (player.stats[i].name === 'total_deaths') {
              deaths = player.stats[i].value;
              continue;
            }

            if (player.stats[i].name === 'total_time_played') {
              playtime = (player.stats[i].value/60)/60;
              continue;
            }
          }

          const cutkd = (kills/deaths).toString().substring(0,4)
          const cutpt = playtime.toString().substring(0,5)

        
          return (
            <div>
              <p><b>K/D: </b>{kills} / {deaths} ({cutkd})</p>
              <p><b>Hours played: </b>{cutpt} hours</p>
            </div>
          );
        }
      }
}

export default Players;