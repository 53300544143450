import logo from './GREMLIN.svg';
import Player from './component/Player.js'
import Css from './App.css';
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'

function App() {

  const james_steam_id = '76561198277579320';
  const jackson_steam_id = '76561198869744507';
  const august_steam_id = '76561198967538962';
  const ethan_steam_id = '76561198035173814';

  return (
    <div className="main">
    <div className="center">
      <div className="header">
      <h1>The Gremlis</h1>
      </div>
    </div>
    <div className="center">
      <Container>
      <Row>
        <Col className="cards">
        <Card style={{ width: '18rem' }}>
          <Card.Img variant="top" src={process.env.PUBLIC_URL + "/GREMLI.webp"} alt='Profile picture of a Gremli'/>
          <Card.Body>
            <Card.Title>Gremli</Card.Title>
            <Card.Text>
              <p>Most likely going to flash you multiple times during the match, it's just what he does.</p>
              <Player steamid={jackson_steam_id}/>
            </Card.Text>
            <Button variant="primary">
            <a className="links" href={'https://steamcommunity.com/profiles/' + jackson_steam_id} target="_blank">Steam</a>
            </Button>
          </Card.Body>
        </Card>
        </Col>
        <Col className="cards">
        <Card style={{ width: '18rem' }}>
          <Card.Img variant="top" src={process.env.PUBLIC_URL + "/GREMLINI.webp"} alt='Profile picture of a Gremli'/>
          <Card.Body>
            <Card.Title>Gremlini</Card.Title>
            <Card.Text>
            <p>Gremlini, a fierce player who never gives up, but also never buys what he is supposed to.</p>
            <Player steamid={james_steam_id}/>
            </Card.Text>
            <Button variant="primary">
            <a className="links" href={'https://steamcommunity.com/profiles/' + james_steam_id} target="_blank">Steam</a>
            </Button>
          </Card.Body>
        </Card>
        </Col>
        <Col className="cards">
        <Card style={{ width: '18rem' }}>
          <Card.Img variant="top" src={process.env.PUBLIC_URL + "/GREMLIN.webp"} alt='Profile picture of a Gremli'/>
          <Card.Body>
            <Card.Title>Gremolata</Card.Title>
            <Card.Text>
            <p>Always vibing, always up for a game of CS. Watch out though, because he may just be vibing behind you.</p>
            <Player steamid={august_steam_id}/>
            </Card.Text>
            <Button variant="primary">
            <a className="links" href={'https://steamcommunity.com/profiles/' + august_steam_id} target="_blank">Steam</a>
            </Button>
          </Card.Body>
        </Card>
        </Col>
        <Col className="cards">
        <Card style={{ width: '18rem' }}>
          <Card.Img variant="top" src={process.env.PUBLIC_URL + "/GREMLUR.webp"} alt='Profile picture of a Gremli'/>
          <Card.Body>
            <Card.Title>Gremlur</Card.Title>
            <Card.Text>
            <p>Probably the best Gremli out here, only downside is he barley plays the game...</p>
            <Player steamid={ethan_steam_id}/>
            </Card.Text>
            <Button variant="primary">
            <a className="links" href={'https://steamcommunity.com/profiles/' + ethan_steam_id} target="_blank">Steam</a>
            </Button>
          </Card.Body>
        </Card>
        </Col>
      </Row>
    </Container>
    </div>
    <div className="center">
    <h1>Clips</h1>
    </div>
    <div className="center">
    <iframe width="560" height="315" src="https://www.youtube.com/embed/9FCsDh7zMgM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    </div>
  );
}

export default App;
